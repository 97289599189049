import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Email_Icon from "../../Email.png"

export default function ForgotPassword(Email) {


  let history = useHistory();
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [responseClass, setResponseClass] = useState('');
  const [emailError, setEmailError] = useState();
  const [isFormValid, setIsFormValid] = useState(false);

  const [showfrom, setShowfrom] = useState(true);

  async function forgotPassword(email) {
    return fetch("https://onboardingserver.herokuapp.com/api/client/forgotpassword?mail=" + email, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(data => data)
  }

  const handleSubmit = async e => {
    e.preventDefault();
    handleEmail(email);
    //  console.log(email);
    if (isFormValid) {
      const response = await forgotPassword(email);
      if (response.status == 200) { //success
        // history.push('/');
        setShowfrom(false);

        setResponseMessage("Dir wurde ein neues Passwort zugesendet. Bitte überprüfe deinen Posteingang.");
        setResponseClass("success-message");
      } else { //error
        setResponseMessage("Entschuldigung etwas ist schiefgelaufen. Versuche es noch einmal.");
        setResponseClass("error-msg");
      }

    }

  }

  function handleEmail(email) {
    let emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!emailpattern.test(email)) {
      setIsFormValid(false);
      setEmailError("Bitte gebe eine gültige E-Mail-Adresse ein.");
    } else {
      setEmail(email);
      setIsFormValid(true);
      setEmailError("");
    }
  }

  let showfromContent = <form style={{marginTop:"50px"}} className="forgot" onSubmit={handleSubmit}>
  <div style={{position:"relative"}} className="mb-3">
    <label htmlFor="exampleInputEmail1" className="form-label login_tb_label">E-Mail-Adresse</label>
    <img className="input_icon"  src={Email_Icon}></img>
    <input style={{color:"black"}} type="email" className="login_tb" id="exampleInputEmail1"  onChange={e => handleEmail(e.target.value)} />
    <div className="errorMsg">{emailError}</div>
    <Link to="/" className=" w-100 forgot_password" ><small style={{fontFamily:"GTWalsheimPro-Regular"}}>Zurück zum Login</small></Link>
  </div>
  <button type="submit" className="login_button w-100">Passwort zusenden</button>
</form>;
 
 if(!showfrom){
  showfromContent = <></>
 }


  return (

    <section className="login-section2 ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading text-center">
              <h2 className="login_heading">Passwort vergessen</h2>
            </div>
            <div className="login-box mt-30">
            <h2 className="login_box_header">Keine Sorge!</h2>
            <p className="login_box_paragraph">Bitte trage deine Email-Adresse ein, um ein neues Passwort zu beantragen</p>
              {showfromContent}
              <div className="heading text-center messages"> 
                <div className={responseClass}>
                  {responseMessage}
                 </div>
                <Link className="logo-back back-link" to="/">
                <svg id="Layer" enable-background="new 0 0 64 64" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"/></svg> 
                Zurück zum Login</Link> 
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  );
}