import React from "react";
import { Link } from "react-router-dom";

export default function DashboardNav(props) {

    let page_btn = <Link to={props.NextPageurl} className="btn btn-primary" target={props.targeturl} > {props.text} </Link>
    if(props.blankurl){
        page_btn = <a href={props.blankurl} target="_blank" className="btn btn-primary"> {props.texturl}   </a>
    }

    return(
      <>
      <section style={{background:"white", marginBottom:"100px"}} className="pb-70 text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">         
              <div className="button-after-video mt-15">
               {page_btn}
              </div>
            </div>
          </div>
        </div>
        </section>
     </> 
    );
  }