import React, { Component } from 'react';
import { InlineWidget } from "react-calendly";


class Meetings extends Component {
    render() {

        return (
            <>
                <section className="metting-section ptb-70 text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="meeting-heading">
                                    <h1 style={{fontFamily:"GTWALSHEIMPRO-MEDIUM"}}>  On-Boarding-Gespräch vereinbaren </h1>
                                    <p style={{fontFamily:"GTWalsheimPro-Regular"}}> Es kann eventuell ein paar Sekunden dauern bis der Kalender geladen ist. </p>
                                    <ul>
                                        <li style={{fontFamily:"GTWalsheimPro-Regular"}}>1. Termin auswählen</li>
                                        <li style={{fontFamily:"GTWalsheimPro-Regular"}}>2. Kontaktdaten eingeben</li>
                                        <li style={{fontFamily:"GTWalsheimPro-Regular"}}>3. Zoom-Link per Mail erhalten</li>
                                        <li style={{fontFamily:"GTWalsheimPro-Regular"}}>4. On-Boarding-Gespräch  </li>
                                    </ul>

                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="emdeb-responsive">
                        <InlineWidget styles={{position:"relative", minWidth:"320px", height:"750px"}} url="https://calendly.com/jangrzanna/60min" />
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Meetings;