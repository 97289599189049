import React from "react";
import InputColor from 'react-input-color';
import { withRouter } from "react-router-dom";
import Loader from "../../../src/loader.gif";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFormValid: true,
      afterloader: {},
      formData: {},
      colorBtn: {
        color1: true,
        color2: false,
        color3: false,
      },
      colorBtnKey: 2
    }
  }

  addColors(param) {
    if (param <= 4) {
      let colorBtn = { ...this.state.colorBtn };
      let key = 'color' + param
      colorBtn[key] = true;
      this.setState({ colorBtn: colorBtn });
      param = param + 1;
      this.setState({ colorBtnKey: param });
    }
  }



  removeColor(param) {
    if (param <= 4) {
      let colorBtn = { ...this.state.colorBtn };
      let key = 'color' + param
      colorBtn[key] = false;
      this.setState({ colorBtn: colorBtn });
      this.setState({ colorBtnKey: param });
    }
  }



  getToken() {
    const tokenString = localStorage.getItem('token');
    if (tokenString == null) {
      //need to redirect login
      this.props.history.push("/login");
    } else {
      return JSON.parse(tokenString).token;
    }
    //  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDM0ZWZkOTgwMzhjOTAwMTUwMmYyNzAiLCJpYXQiOjE2MTQwODIwMTB9.1VQZaQbiGsm1gkPzDmyE9obbMA9pfPY2T7GoorpWBms';
  }

  getForm() {
    
    return fetch('https://onboardingserver.herokuapp.com/api/questions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      }
    })
  }
  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

  isValidURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const response = await this.getForm();
    const json = await response.json();
    this.setState({ formData: json });
    this.setState({ isLoading: false });
  }
  handleChange = (e) => {
    let field_name_group = e.target.name.split('.');
    let group_name = field_name_group[0];
    let input_name = field_name_group[1];
    let formData = { ...this.state.formData };
    let userResponse = e.target.value.trim();
    if (this.isEmpty(userResponse)) {
      formData[group_name][input_name]['error'] = 'Dieses Feld ist erforderlich';
      formData[group_name][input_name]['answer'] = userResponse;
    } else {
      // set value in formData state
      formData[group_name][input_name]['error'] = '';
      formData[group_name][input_name]['answer'] = e.target.value;
    }
    this.setState({ formData: formData });
  }

  handleColorChange = (e, fieldName) => {
    let field_name_group = fieldName.split('.');
    let group_name = field_name_group[0];
    let input_name = field_name_group[1];
    // set value in formData state
    let formData = { ...this.state.formData };
    formData[group_name][input_name]['answer'] = e.hex;
    /*let userResponse = e.hex;
    if (this.isEmpty(userResponse)) {
      formData[group_name][input_name]['error'] = 'Dieses Feld ist erforderlich';
      formData[group_name][input_name]['answer'] = userResponse;
    } else {
      // set value in formData state
      formData[group_name][input_name]['error'] = '';
      formData[group_name][input_name]['answer'] = e.hex;
    }*/
    this.setState({ formData: formData });
  }

  validateForm() {
    let formData = { ...this.state.formData };
    let isError = false;
    
    if (this.isEmpty(formData.buyer_persona.name.answer)) {
      formData.buyer_persona.name.error = 'Dieses Feld ist erforderlich';
      isError = true;
    } else {
      formData.buyer_persona.name.error = '';
    }

    if (this.isEmpty(formData.buyer_persona.employment_relationship.answer)) {
      formData.buyer_persona.employment_relationship.error = 'Dieses Feld ist erforderlich';
      isError = true;
    } else {
      formData.buyer_persona.employment_relationship.error = '';
    }

    if (this.isEmpty(formData.buyer_persona.environment.answer)) {
      formData.buyer_persona.environment.error = 'Dieses Feld ist erforderlich';
      isError = true;
    } else {
      formData.buyer_persona.environment.error = '';
    }

    if (this.isEmpty(formData.buyer_persona.intellect.answer)) {
      formData.buyer_persona.intellect.error = 'Dieses Feld ist erforderlich';
      isError = true;
    } else {
      formData.buyer_persona.intellect.error = '';
    }

    if (this.isEmpty(formData.buyer_persona.demography.answer)) {
      formData.buyer_persona.demography.error = 'Dieses Feld ist erforderlich';
      isError = true;
    } else {
      formData.buyer_persona.demography.error = '';
    }

    if (this.isEmpty(formData.buyer_persona.interests.answer)) {
      formData.buyer_persona.interests.error = 'Dieses Feld ist erforderlich';
      isError = true;
    } else {
      formData.buyer_persona.interests.error = '';
    }

    if (this.isEmpty(formData.buyer_persona.goals.answer)) {
      formData.buyer_persona.goals.error = 'Dieses Feld ist erforderlich';
      isError = true;
    } else {
      formData.buyer_persona.goals.error = '';
    }

    if (this.isEmpty(formData.buyer_persona.problems.answer)) {
      formData.buyer_persona.problems.error = 'Dieses Feld ist erforderlich';
      isError = true;
    } else {
      formData.buyer_persona.problems.error = '';
    }

    if (this.isEmpty(formData.buyer_persona.services.answer)) {
      formData.buyer_persona.services.error = 'Dieses Feld ist erforderlich';
      isError = true;
    } else {
      formData.buyer_persona.services.error = '';
    }

    if (this.isEmpty(formData.buyer_persona.values.answer)) {
      formData.buyer_persona.values.error = 'Dieses Feld ist erforderlich';
      isError = true;
    } else {
      formData.buyer_persona.values.error = '';
    }


    this.setState({ formData: formData });
    if (isError) {
      this.setState({ isFormValid: false });
    }
    return new Promise((resolve, reject) => {
      resolve(isError);
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validateForm().then((isError) => {
      console.log("IS ERROR ==> " + isError)
     if(!isError){
      this.setState({ isLoading: true });
      fetch('https://onboardingserver.herokuapp.com/api/questions/buyerpersona', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.getToken()
        },
        body: JSON.stringify(this.state.formData)
      }).then(response => {
        this.props.history.push("/dashboard-5");
      }).catch(error => {
        console.log(error);
      });}
    }).catch(err => console.log('There was an error:' + err))
    this.setState({ isLoading: false });
  }

 

  render() {

    let formData = <div id="loading"><img id="loading-image" src={Loader} alt="Loading..." /></div>
    let afterloader = <div id="loading">123<img id="loading-image" src={Loader} alt="Loading..." /></div> 
    if (!this.state.isLoading) {
      formData = <form onSubmit={this.handleSubmit}>
        <div className="form-grouping-custom">
          <h3>{this.state.formData.buyer_persona.text}</h3>

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.name.question}</h4>
            <input type="text"
              
              name="buyer_persona.name"
              class="w-100"
              value={this.state.formData.buyer_persona.name.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.name.error}</div>

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.look.question}</h4>
            <input type="text"
              
              name="buyer_persona.look"
              class="w-100"
              value={this.state.formData.buyer_persona.look.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.look.error}</div>



          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.employment_relationship.question}</h4>
            <textarea type="text"
              name="buyer_persona.employment_relationship"
              class="w-100"
              value={this.state.formData.buyer_persona.employment_relationship.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.employment_relationship.error}</div>

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.work_success.question}</h4>
            <textarea type="text"
              name="buyer_persona.work_success"
              class="w-100"
              value={this.state.formData.buyer_persona.work_success.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.work_success.error}</div>


          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.environment.question}</h4>
            <textarea type="text"
              name="buyer_persona.environment"
              class="w-100"
              value={this.state.formData.buyer_persona.environment.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.environment.error}</div>

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.intellect.question}</h4>
            <textarea type="text"
              name="buyer_persona.intellect"
              class="w-100"
              value={this.state.formData.buyer_persona.intellect.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.intellect.error}</div>


          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.demography.question}</h4>
            <textarea type="text"
              name="buyer_persona.demography"
              class="w-100"
              value={this.state.formData.buyer_persona.demography.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.demography.error}</div>


          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.interests.question}</h4>
            <textarea type="text"
              name="buyer_persona.interests"
              class="w-100"
              value={this.state.formData.buyer_persona.interests.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.interests.error}</div>


          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.goals.question}</h4>
            <textarea type="text"
              name="buyer_persona.goals"
              class="w-100"
              value={this.state.formData.buyer_persona.goals.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.goals.error}</div>

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.problems.question}</h4>
            <textarea type="text"
              name="buyer_persona.problems"
              class="w-100"
              value={this.state.formData.buyer_persona.problems.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.problems.error}</div>

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.services.question}</h4>
            <textarea type="text"
              name="buyer_persona.services"
              class="w-100"
              value={this.state.formData.buyer_persona.services.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.services.error}</div>

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.values.question}</h4>
            <textarea type="text"
              name="buyer_persona.values"
              class="w-100"
              value={this.state.formData.buyer_persona.values.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.values.error}</div>


          </div>

          <div className="form-grouping-custom">
          {/* <h3>{this.state.formData.buyer_persona.text}</h3> */}

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.idea_short.question}</h4>
            <textarea type="text"
              
              name="buyer_persona.idea_short"
              class="w-100"
              value={this.state.formData.buyer_persona.idea_short.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.idea_short.error}</div>

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.idea_features.question}</h4>
            <textarea type="text"
              
              name="buyer_persona.idea_features"
              class="w-100"
              value={this.state.formData.buyer_persona.idea_features.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.idea_features.error}</div>

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.idea_budget.question}</h4>
            <input type="text"
              
              name="buyer_persona.idea_budget"
              class="w-100"
              value={this.state.formData.buyer_persona.idea_budget.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.idea_budget.error}</div>

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.idea_hours.question}</h4>
            <input type="text"
              
              name="buyer_persona.idea_hours"
              class="w-100"
              value={this.state.formData.buyer_persona.idea_hours.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.idea_hours.error}</div>

          <div className="form-group">
            <h4>{this.state.formData.buyer_persona.idea_solution.question}</h4>
            <textarea type="text"
              
              name="buyer_persona.idea_solution"
              class="w-100"
              value={this.state.formData.buyer_persona.idea_solution.answer}
              onChange={this.handleChange} />
          </div>
          <div className="errorMsg">{this.state.formData.buyer_persona.idea_solution.error}</div>
   

          </div>

        
        <button type="submit" className="btn btn-primary submit-from w-100 mt-30">Weiter zum nächsten Schritt</button>

      </form>;
    }

    return (

      <>
        <section class="login-section pb-70">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="heading text-center">
                  <h1 style={{fontFamily:"GTWALSHEIMPRO-MEDIUM"}}>Fragebogen</h1>
                </div>
                <div className="mt-30">
                  {formData}
                </div>
              </div>
              </div>
          </div>
        </section>

      </>

    )
  }
}
export default withRouter(Form) 