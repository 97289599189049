import React from "react";
import ReactDOM from "react-dom";
 import { BrowserRouter } from "react-router-dom";
 import Header from './Header';
 import Footer from './Footer';
 import './style.css';
 import 'bootstrap/dist/css/bootstrap.min.css';
 import "typeface-roboto";
 import './fonts/font.css'


import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
   
            <App />
      <Footer />  
    </BrowserRouter>
  </React.StrictMode>,
  rootElement
);
