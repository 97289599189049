import footerlogo from './Logo-B-4.png';
import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
       <>
        <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-block">
              <img src={footerlogo} alt="logo" className="footer-logo"/>  
             <h2 style={{color:"#0C1079", fontSize:"25px", fontWeight:600, fontFamily:"GTWalsheimPro-Regular", marginBottom:"16px", marginTop:"20px"}}> 7GB App GmbH  
            </h2>
            <p style={{fontSize:"15px", color:"#000", marginBottom:"32px", fontWeight:300, fontFamily:"GTWalsheimPro-Regular"}}>
              Große Gallusstraße 18, 60312 Frankfurt am Main
            </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-block">
              <h3 style={{color:"#000", fontSize:"23px", fontWeight:"600", marginBottom:"30px"}}>Kontakt</h3>
              <p style={{color:"#758389", fontSize:"15px", fontWeight:"300"}}> Telefon
                <span style={{color:"#000", marginTop: "6px"}}> +49 176 30138312 </span>
              </p>
              <p style={{color:"#758389", fontSize:"15px", fontWeight:"300"}}> E-Mail
              <span style={{color:"#000", marginTop: "6px", marginBottom: "29px"}}> hey@sevengb.de</span></p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-block">
            <h3 style={{color:"#000", fontSize:"23px", fontWeight:"600", marginBottom:"24px"}}>Rechtliches</h3>
            <p><a style={{color:"#000", fontSize:"15px", fontWeight:"600" }} href="https://sevengb.de/impressum" target="_blank">Impressum</a></p>
            <p><a style={{color:"#000", fontSize:"15px", fontWeight:"600"}} href="https://sevengb.de/datenschutz" target="_blank">Datenschutz</a></p>
            <p><a style={{color:"#000", fontSize:"15px", fontWeight:"600"}} href="https://sevengb.de/agb" target="_blank">Allgemeine Geschäftsbedingungen</a></p>

            <p className="footer-copyright" style={{color:"#758389", fontSize:"15px", fontWeight:"600", marginTop: "32px"}}>Copyright 7GB App GmbH 2021</p>
              {/* <p className="copyright-text m-0 text-md-right text-center">©Copyright 2021, All rights reserved</p> */}
            </div>
          </div> 
        </div>
        <div className="row">
          <div className="col-12">
            <div className="footer-note text-center">
              <p><span className="d-inline">Notiz:</span> Die Angebote und Inhalte dieser Website richten sich ausdrücklich an Gewerbetreibende und Unternehmer im Sinne von § 14 BGB.</p>
            </div>
          </div>
          <div className="col-12">
            <div className="footer-bottom-text text-center">This site is not a part of the Facebook TM website or Facebook TM Inc. Additionally, this site is NOT endorsed by Facebook TM in any way.</div>
            <div className="footer-bottom-text text-center">FACEBOOK TM is a trademark of FACEBOOK TM, Inc.</div>
          </div>
        </div>
      </div>
    </footer>
       </>
     );
};

export default Footer;