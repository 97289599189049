import Invertedlogo from './Logo-Inverted.png';
import MenuIcon from './Menu.png';
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";



const Header = (props) => {

  const [ menuState, setMenuState ] = useState(false);

  const client_stage = props.client_stage
  
  return (
       <>
        <header>
          <nav className="navbar navbar-expand-lg custom-nav">
            <div className="container">
              <Link style={{zIndex:100}} className="navbar-brand" to="/"  >
                <img  src={Invertedlogo} width="110" alt="logo"/> 
              </Link>
              {/* <img onClick={(e) => setMenuState(!menuState)} style={{zIndex:100}} className="menu_icon navbar-nav ml-auto d-none d-md-block" src={MenuIcon}/> */}
              <ul className="navbar-nav ml-auto d-none d-md-block">
                <li className="d-flex">
                  <span onClick={props.clicked} >Abmelden</span>
                </li>
              </ul>
            </div>
          </nav>
        </header>


        {menuState ? (<div style={{position:"absolute", zIndex:200, background:"white", right:0, border:"1px solid black"}}>
          <ul>
            <li>
              <Link  onClick={(e) => setMenuState(!menuState)} to="/">On Boarding</Link>
            </li>
            <li>
              {client_stage >= 1 ? (<Link  onClick={(e) => setMenuState(!menuState)} to="/design-1">Design Funnel</Link>) : (<Link style={{opacity:"0.4", cursor:"default", pointerEvents:"none"}} to="/design-1">Design Funnel (Coming Soon)</Link>)}
            </li>
            <li>
            {client_stage >= 2 ? (<a  onClick={(e) => setMenuState(!menuState)} href="https://project.sevengb.de">Design-Projekt verwalten</a>) : (<a style={{opacity:"0.4", cursor:"default", pointerEvents:"none"}} href="https://project.sevengb.de">Design-Projekt verwalten (Coming Soon)</a>)}
            </li>
          </ul>
           <ul className="navbar-nav ml-auto d-none d-md-block">
                <li className="d-flex">
                  <span onClick={props.clicked} >Abmelden</span>
                </li>
              </ul>
        </div>): null}
        
       </>
     );
};

export default Header;
