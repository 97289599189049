import React, { useEffect } from "react";
import ReactPlayer from 'react-player/vimeo';
import { Link, useHistory } from "react-router-dom";
import Invertedlogo from './../../Logo-Inverted.png';
import Lines from './../../lines.png';
import Loader from "../../../src/loader.gif";



export default function ActivateDesign(props) {


  
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const history = useHistory();


function getForm(token, history) {

  console.log(token)

  props.setToken({token})




  // localStorage.setItem("token", token)


  fetch('https://onboardingserver.herokuapp.com/api/client/stage?stage=1', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
      }).then(response => {


        console.log(response)
        if (response.ok) {
          response.text().then(json => {
            console.log("RESPONSE ENDED SUCCESSFULLY");
            history.push("/design-1")
          });
        }
      }).catch(error => {
        console.log(error);
      });
}



useEffect(() => {

  let token = getParameterByName('token')

  getForm(token, history)
})




  return (
    <> 
      <div style={{height:"600px"}}>
      <div id="loading"><img id="loading-image" src={Loader} alt="Lade..." /></div>
      </div>
    </>
  );
}