import React from "react";
import InputColor from 'react-input-color';
import { withRouter } from "react-router-dom";
import Loader from "../../../src/loader.gif";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFormValid: true,
      amountOfInputs: 1,
      afterloader: {},
      formData: {},
      colorBtn: {
        color1: true,
        color2: false,
        color3: false,
      },
      colorBtnKey: 2
    }
  }

  addColors(param) {
    if (param <= 4) {
      let colorBtn = { ...this.state.colorBtn };
      let key = 'color' + param
      colorBtn[key] = true;
      this.setState({ colorBtn: colorBtn });
      param = param + 1;
      this.setState({ colorBtnKey: param });
    }
  }



  removeColor(param) {
    if (param <= 4) {
      let colorBtn = { ...this.state.colorBtn };
      let key = 'color' + param
      colorBtn[key] = false;
      this.setState({ colorBtn: colorBtn });
      this.setState({ colorBtnKey: param });
    }
  }



  getToken() {
    const tokenString = localStorage.getItem('token');
    if (tokenString == null) {
      //need to redirect login
      this.props.history.push("/login");
    } else {
      return JSON.parse(tokenString).token;
    }
    //  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDM0ZWZkOTgwMzhjOTAwMTUwMmYyNzAiLCJpYXQiOjE2MTQwODIwMTB9.1VQZaQbiGsm1gkPzDmyE9obbMA9pfPY2T7GoorpWBms';
  }

  getForm() {
    
    return fetch('https://onboardingserver.herokuapp.com/api/questions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      }
    })
  }
  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

  isValidURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const response = await this.getForm();
    const json = await response.json();
    console.log(JSON.stringify(json.user_stories))
    this.setState({ formData: json });
    this.setState({ isLoading: false });
  }
  handleChange = (e) => {
    let field_name_group = e.target.name.split('.');
    let array_number = field_name_group[0];
    let input_name = field_name_group[1];
    let formData = { ...this.state.formData };
    let userResponse = e.target.value;

    formData['user_stories'][array_number][input_name] = userResponse;

    // if (this.isEmpty(userResponse)) {
    //   formData[group_name][input_name]['error'] = 'Dieses Feld ist erforderlich';
    //   formData['user_stories'][group_name][input_name] = userResponse;
    // } else {
    //   // set value in formData state
    //   formData[group_name][input_name]['error'] = '';
    //   formData[group_name][input_name]['answer'] = e.target.value;
    // }
    this.setState({ formData: formData });
  }

  handleColorChange = (e, fieldName) => {
    let field_name_group = fieldName.split('.');
    let group_name = field_name_group[0];
    let input_name = field_name_group[1];
    // set value in formData state
    let formData = { ...this.state.formData };
    formData[group_name][input_name]['answer'] = e.hex;
    /*let userResponse = e.hex;
    if (this.isEmpty(userResponse)) {
      formData[group_name][input_name]['error'] = 'Dieses Feld ist erforderlich';
      formData[group_name][input_name]['answer'] = userResponse;
    } else {
      // set value in formData state
      formData[group_name][input_name]['error'] = '';
      formData[group_name][input_name]['answer'] = e.hex;
    }*/
    this.setState({ formData: formData });
  }

  validateForm() {
    let formData = { ...this.state.formData };
    let isError = false;
    
    if (this.isEmpty(formData.design.corporate_design.answer)) {
      formData.design.corporate_design.error = 'Dieses Feld ist erforderlich';
      console.log("1 isError = true")
      isError = true;
    } else {
      formData.design.corporate_design.error = '';
    }

        
    // if (this.isEmpty(formData.design.corporate_link.answer)) {
    //   formData.design.corporate_link.error = 'Dieses Feld ist erforderlich';
    //   console.log("2 isError = true")
    //   isError = true;
    // } else {
    //   formData.design.corporate_link.error = '';
    // }

        
    // if (this.isEmpty(formData.design.color.answer)) {
    //   formData.design.color.error = 'Dieses Feld ist erforderlich';
    //   console.log("3 isError = true")
    //   isError = true;
    // } else {
    //   formData.design.color.error = '';
    // }


    if (this.isEmpty(formData.references.link1.answer)) {
      formData.references.link1.error = 'Dieses Feld ist erforderlich';
      console.log("4 isError = true")
      isError = true;
    } else {
      if(this.isValidURL(formData.references.link1.answer)){
        formData.references.link1.error = '';
      }else{
        formData.references.link1.error = 'Please enter a valid URL.';
        isError = true;
        console.log("5 isError = true")
      }
      
    }

    if (this.isEmpty(formData.references.link2.answer)) {
      formData.references.link2.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("6 isError = true")
    } else {
      if(this.isValidURL(formData.references.link2.answer)){
        formData.references.link2.error = '';
      }else{
        formData.references.link2.error = 'Please enter a valid URL.';
        isError = true;
        console.log("7 isError = true")
      }
    }

    if (this.isEmpty(formData.references.link3.answer)) {
      formData.references.link3.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("8 isError = true")
    } else {
      if(this.isValidURL(formData.references.link3.answer)){
        formData.references.link3.error = '';
      }else{
        formData.references.link3.error = 'Please enter a valid URL.';
        isError = true;
        console.log("9 isError = true")
      }
    }

    if (this.isEmpty(formData.references.priority1.answer)) {
      formData.references.priority1.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("10 isError = true")
    } else {
      formData.references.priority1.error = '';
    }

    if (this.isEmpty(formData.references.priority2.answer)) {
      formData.references.priority2.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("11 isError = true")
    } else {
      formData.references.priority2.error = '';
    }

    if (this.isEmpty(formData.references.priority3.answer)) {
      formData.references.priority3.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("12 isError = true")
    } else {
      formData.references.priority3.error = '';
    }

    if (this.isEmpty(formData.references.notice1.answer)) {
      formData.references.notice1.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("13 isError = true")
    } else {
      formData.references.notice1.error = '';
    }

    if (this.isEmpty(formData.references.notice2.answer)) {
      formData.references.notice2.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("14 isError = true")
    } else {
      formData.references.notice2.error = '';
    }

    if (this.isEmpty(formData.references.notice3.answer)) {
      formData.references.notice3.error = 'Dieses Feld ist erforderlich';
      isError = true;
      console.log("15 isError = true")
    } else {
      formData.references.notice2.error = '';
    }


   

    this.setState({ formData: formData });
    if (isError) {
      this.setState({ isFormValid: false });
    }
    return new Promise((resolve, reject) => {
      resolve(isError);
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();


    console.log("SUBMIT DESIGN FORM")

    this.validateForm().then((isError) => {

      console.log(isError)

     if(!isError){
      this.setState({ isLoading: true });
      fetch('https://onboardingserver.herokuapp.com/api/questions/user-stories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.getToken()
        },
        body: JSON.stringify(this.state.formData)
      }).then(response => {
        this.props.history.push("/design-6");
      }).catch(error => {
        console.log(error);
      });}
    }).catch(err => console.log('There was an error:' + err))
    this.setState({ isLoading: false });
  }


  increaseAmountOfInputs = () => {

    let formData = { ...this.state.formData };

    formData.user_stories.push({title:"", description:""})

    this.setState({ formData: formData });


  }

  decreaseAmountOfInputs = () => {

    let formData = { ...this.state.formData };

    let user_story_length = formData.user_stories.length;

    formData.user_stories.splice(user_story_length -1, 1);


    this.setState({ formData: formData });

  }

 

  render() {

    let formData = <div id="loading"><img id="loading-image" src={Loader} alt="Loading..." /></div>
    let afterloader = <div id="loading">123<img id="loading-image" src={Loader} alt="Loading..." /></div> 



    let userStoryInput = (number, title, description) =>  <div>
                          <div className="form-group">
                            <div className="input-and-headings">
                              <h4>{number + ". User Story (Titel)"}</h4>
                              <input type="text" name={(number-1)+".title"} className="w-100" value={title}
                                
                                onChange={this.handleChange} />
                              <div className="errorMsg"></div>
                            </div>
                            <div className="input-and-headings">
                              <h4>{number + ". User Story (Beschreibung)"}</h4>
                              <textarea name={(number-1)+".description"} className="w-100" value={description}
                                onChange={this.handleChange}></textarea>
                              <div className="errorMsg"></div>
                            </div>
                          </div>
                          {this.state.formData.user_stories && this.state.formData.user_stories.length == number && number != 1 ? <button className="remove_button" onClick={this.decreaseAmountOfInputs} type="button">-</button> : null}
                          
                          <div className="seperator"></div>
                        </div>

    let userStoryList = [];

    console.log(this.state.formData.user_stories)
    let amountOfInputs = 1;
    if(this.state.formData.user_stories && this.state.formData.user_stories.length > 0)amountOfInputs = this.state.formData.user_stories.length
    

    for(let i = 0; i < amountOfInputs; i++){

      let title;
      let description;
      if(this.state.formData.user_stories && this.state.formData.user_stories[0]){

        title = this.state.formData.user_stories[i].title
        description = this.state.formData.user_stories[i].description

      }

      userStoryList.push(userStoryInput(i+1, title, description))
    }



    if (!this.state.isLoading) {
      formData = <form onSubmit={this.handleSubmit}>
    
        <div className="form-grouping-custom">
          <h3>Schreibe deine User Stories so detailiert wie möglich auf</h3>
            {userStoryList}
          <button  className="add_button" onClick={this.increaseAmountOfInputs} type="button">+</button>
        </div>
        
        <button style={{marginTop:"50px", marginBottom:"50px"}} type="submit" className="btn btn-primary submit-from w-100">Weiter zum nächsten Schritt</button>

      </form>;
    }

    return (

      <>
        <section style={{background:"white"}} class=" pb-70">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="heading text-center">
                </div>
                <div className="mt-30">
                  {formData}
                </div>
              </div>
              </div>
          </div>
        </section>

      </>

    )
  }
}
export default withRouter(Form) 