import { Route, Switch } from 'react-router-dom';
import './App.css';
import Header from './Header';
import OuterHeader from './OuterHeader';

import Dashboard from './components/Dashboard/Dashboard';
import DashboardNav from './components/Dashboard/DashboardNav';
import Login from './components/Login/Login';
import Preferences from './components/Preferences/Preferences';
import useToken from './components/App/useToken';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';

import Form_BuyerPersona from './components/Form/Form_BuyerPersona'
import Form_Company from './components/Form/Form_Company'
import Form_Design from './components/Form/Form_Design'
import Form_Imagination from './components/Forms_Design/Form_Imagination'
import Form_Components from './components/Forms_Design/Form_Components'
import Form_User_Stories from './components/Forms_Design/Form_User_Stories'

import WelcomeScreen from './components/WelcomeScreen/WelcomeScreen'
import RequestDesign from './components/RequestDesign/RequestDesign'

import ActivateDesign from './components/ActivateDesign/ActivateDesign'


import Meetings from './components/Meetings/Meetings'
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';


const App = () => {
  const { token, setToken } = useToken();

  const [ client_stage, setClientStage ] = useState(0);


  let history = useHistory();
  const logout = () => {
    localStorage.removeItem('token');
    history.push('/');
    setToken('');
  }



  function getForm(token) {

    console.log(token)
    
  
    fetch('https://onboardingserver.herokuapp.com/api/client/stage', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
        }).then(response => {
  
  
          console.log(response)
          if (response.ok) {
            response.json().then(json => {
              console.log("RESPONSE ENDED SUCCESSFULLY ==> " + JSON.stringify(json));

              setClientStage(json.stage)
            });
          }
        }).catch(error => {
          console.log(error);
        });
  }



  useEffect(() => {

  
    getForm(token)
  })



  let startOfOnBoarding = <div>
                                <Header client_stage={client_stage} clicked={() => logout()} />
                                <Dashboard description={<p style={{paddingTop:"50px", textAlign:"left"}}>Bitte beachte, dass der Onboarding Prozess ca. 45 - 60 Minuten dauern wird. Das ist der
                                                        effektivste Onboarding Prozess, den du bis dahin erlebt haben wirst. <br></br>
                                                        Wenn der Prozess abgeschlossen ist, haben wir alle Informationen, um die Zusammenarbeit
                                                        gemeinsam mit dir zu starten.</p>}
                                            url="https://player.vimeo.com/video/568980093?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=56ba2cd4f0" previousPageurl="" title={[<b> Schritt 1:</b>, " Wie der Onboarding Prozess abläuft"]} />
                                <DashboardNav text="Weiter zum nächsten Schritt" NextPageurl="dashboard-2" />
                          </div>



  let startOfDesign = <div>
                        <Header client_stage={client_stage}  clicked={() => logout()} />
                        <WelcomeScreen text="Starte mit dem ersten Schritt" header="7GB Design Funnel" description="Herzlich willkommen zu unserem Design Funnel, in welchem wir gemeinsam deine Design-Vorstellungen durchgehen und aufnehmen werden." NextPageurl="design-2"></WelcomeScreen>
                      </div>







  let homeScreen = <Switch>
    <Route exact path="/">
      <OuterHeader />
      <Login setToken={setToken} />
    </Route>
    <Route path="/forgot-password">
      <OuterHeader />
      <ForgotPassword />
    </Route>
    <Route exact path="/activate-design">
    <OuterHeader />
        <ActivateDesign setToken={setToken} history={history}></ActivateDesign>
      </Route>
    <Route path="*">
      <OuterHeader />
      <Preferences />
    </Route>
  </Switch>

  if (token) {
    homeScreen = <Switch>
      <Route exact path="/">{startOfOnBoarding}
      </Route>
      <Route exact path="/design-1">{startOfDesign}
      </Route>

      <Route exact path="/dashboard-2">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980282?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=e074ffdc86" previousPageurl="/" title={[<b> Schritt 2:</b>, " Die richtige Einstellung für unsere Zusammenarbeit"]} />
        <DashboardNav text="Weiter zum nächsten Schritt" NextPageurl="dashboard-3" />
      </Route>
      <Route exact path="/dashboard-3">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980444?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=331f883f86" previousPageurl="dashboard-2" title={[<b> Schritt 3:</b>, " Unsere Zusammenarbeit"]} />
        <DashboardNav text="Weiter zum nächsten Schritt" NextPageurl="dashboard-4" />
      </Route>
      {/* <Route exact path="/dashboard-4">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Dashboard url="https://vimeo.com/520856951" previousPageurl="dashboard-3" title={[<b> Schritt 4:</b>, "Alles über deine Zielgruppe & Idee"]} />
        <Form_Company />
      </Route> */}
      <Route exact path="/dashboard-4">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=26fbe463ae" previousPageurl="dashboard-3" title={[<b> Schritt 4:</b>, " Alles über deine Zielgruppe & Idee"]} />
        <Form_BuyerPersona />
      </Route>
      <Route exact path="/dashboard-5">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Dashboard previousPageurl="dashboard-4" title={[<b> Schritt 5:</b>, " Deine Design Vorstellungen"]} />
        <Form_Design />
      </Route>
      <Route exact path="/dashboard-6">
          <Header client_stage={client_stage} clicked={() => logout()} />
          <Dashboard url="https://player.vimeo.com/video/568980638?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=05b988247f" 
                      previousPageurl="dashboard-5" title={[<b> Schritt 6:</b>, " Unser erster Workshop"]} />
          <DashboardNav text="Termin buchen" NextPageurl="/meeting"      />
        </Route>
      
      {/* <Route exact path="/dashboard-4">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Dashboard url="https://youtu.be/D7ghIuf0V-o" previousPageurl="dashboard-3" title={[<b> Schritt 4:</b>, "Was für Ergebnisse zu erwarten sind"]} />
        <DashboardNav text="" targeturl="_blank"
          blankurl="https://meetings.hubspot.com/nilslukasbaumann"
          texturl="Hubspot Calendar" />
      </Route> */}
     <Route path="/meeting">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Meetings />
      </Route>


      

      <Route exact path="/design-2">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980282?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=e074ffdc86"  previousPageurl="/design-1" title={[<b> Schritt 1:</b>, " Ablauf der Design-Entwicklung"]} />
        <DashboardNav text="Weiter zum nächsten Schritt" NextPageurl="design-3" />
      </Route>

      <Route exact path="/design-3">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980282?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=e074ffdc86"  previousPageurl="design-2" title={[<b> Schritt 2:</b>, " Deine Design Vorstellungen"]} />
        <Form_Imagination />
      </Route>

      <Route exact path="/design-4">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980282?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=e074ffdc86"  previousPageurl="design-3" title={[<b> Schritt 3:</b>, " Abfrage der Haupt-Komponenten"]} />
        <Form_Components />
      </Route>

      <Route exact path="/design-5">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980282?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=e074ffdc86"  previousPageurl="design-4" title={[<b> Schritt 4:</b>, " Abfrage der User-Stories"]} />
        <Form_User_Stories />
      </Route>

      <Route exact path="/design-6">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Dashboard url="https://player.vimeo.com/video/568980282?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=e074ffdc86"  previousPageurl="design-5" title={[<b> Schritt 5:</b>, " Weiteres Vorgehen"]} />
        <DashboardNav text="Weiter zum nächsten Schritt" NextPageurl="design-7" />
      </Route>

      <Route exact path="/design-7">
      <Header client_stage={client_stage} clicked={() => logout()} />
      <RequestDesign client_stage={client_stage} token={token} text="Design in Auftrag geben" header="Das war's schon!" description="Bevor du dein Design in Auftrag gibst, komme erst in ein Gespräch mit Jan oder Nils, sodass wir gemeinsam noch einmal über alle Punkte schauen können." NextPageurl="design-2"></RequestDesign>
      </Route>

      
      <Route exact path="/activate-design">
      <Header client_stage={client_stage} clicked={() => logout()} />
        <ActivateDesign history={history}></ActivateDesign>
      </Route>

      <Route path="*">
        <Header client_stage={client_stage} clicked={() => logout()} />
        <Preferences />
      </Route>
    </Switch>
  }

  return (
    <>
      {homeScreen}
    </>
  );
};

export default App;