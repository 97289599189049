import React, { useState }  from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Loader from "../../../src/loader.gif";
import Email from "../../Email.png"
import Password from "../../Password.png"
import See_Password from "../../see_password.png"


async function loginUser(credentials) {
    return fetch('https://onboardingserver.herokuapp.com/api/client/Login', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(response => {
            if(response.status === 200){
                return response.json();
            }else{
                return false;
            }
        })
}

export default function Login({ setToken }) {

    let history = useHistory();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState();
 
    const [password, setPassword] = useState('');
    const [PasswordError, setPasswordError] = useState();
    const [isFormValid, setIsFormValid] = useState(false);

    const [responseClass, setResponseClass] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();
        handleEmail(email);
        handlePassword(password);
        setResponseClass("after-loading");
        if(isFormValid){
            const token = await loginUser({
                email,
                password
            });
            if(token){
                setIsFormValid(true);
                setEmailError("");
                setToken(token);
               // setResponseClass("login");
                history.push('/');
            }else{
                setIsFormValid(false);
                setEmailError("Etwas ist schiefgelaufen. Bitte versuche es erneut.");
                setResponseClass("");
            }
            
        }
        
    };

    function handleEmail(email){ 
        let emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!emailpattern.test(email)) {
            setIsFormValid(false);
            setEmailError("Bitte gebe eine gültige E-Mail-Adresse ein.");
        }else{
            setEmail(email);
            setIsFormValid(true);
            setEmailError("");
        }
    }

    function handlePassword(password){ 
             password= password.trim();
            if ( password.length <1 ) {
            setIsFormValid(false);
            setPasswordError("Bitte gebe dein Passwort ein.");
          }else {
            setPassword(password);
            setIsFormValid(true);
            setPasswordError(""); 
          }
    }


    function showHidePassword(){


        const password = document.querySelector('#exampleInputPassword1');

        console.log(password)

        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';

        console.log(type)

        password.setAttribute('type', type);
    }

  return(
            <section className="login-section2 ptb-70">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="heading text-center">
                        <h2 className="login_heading" >Anmelden</h2>
                        </div>
                        <div className="login-box mt-30 loader-wrapper">
                            <h2 className="login_box_header">Willkommen zurück</h2>
                            <p className="login_box_paragraph">Bitte melde dich an, um in unserer Plattform fortzufahren.</p>
                        <form style={{marginTop:"50px"}} onSubmit={handleSubmit}>
                            <div style={{position:"relative"}} className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label login_tb_label">E-Mail-Adresse</label>
                            <img className="input_icon"  src={Email}></img>
                            <input style={{color:"black"}} type="email" className="login_tb" id="exampleInputEmail1"  onChange={e => handleEmail(e.target.value)} />
                            <div className="errorMsg">{emailError}</div>
                            </div>
                            <div style={{position:"relative"}} className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label login_tb_label">Passwort</label>
                            <img style={{top: "42px"}} className="input_icon" src={Password}></img>
                            <img onClick={showHidePassword} className="show_password_icon" src={See_Password}></img>
                            <input type="password" className="login_tb" id="exampleInputPassword1" onChange={e => handlePassword(e.target.value)}/>
                            <div className="errorMsg">{PasswordError}</div>                            
                            </div>
                            <Link style={{textAlign:"right"}} to="/forgot-password" className=" w-100 forgot_password">  <small> Passwort vergessen?</small> </Link>
                            <button type="submit" className="login_button w-100">Anmelden</button>
                        </form>
                            <div className={responseClass}> 
                                <div id="loading"><img id="loading-image" src={Loader} alt="Lade..." /></div>
                             </div>
                        </div>
                       </div>
                    </div>
                </div>
            </section>



  )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
  }