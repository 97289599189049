import React from 'react';

export default function Preferences() {
  return(
    <section className="login-section ptb-70">
    <div className="container">
        <div className="row">
        <div className="col-md-12">
            <div className="heading text-center">
            <h1>404 Page Not Found</h1>
            </div>
           
           </div>
        </div>
    </div>
</section>
  );
}