import Invertedlogo from './Logo-Inverted.png';
import { Link } from "react-router-dom";
import React from "react";

const OuterHeader = () => {
  
  return (
       <>
        <header>
          <nav style={{display:"block"}} className="navbar navbar-expand-lg custom-nav outer_header">
            <div className="container">
              <Link className="navbar-brand" to="/"  >
                <img src={Invertedlogo} width="110" alt="logo"/> 
              </Link>
              <ul className="navbar-nav ml-auto">
                <li>
                </li>
              </ul>
            </div>
          </nav>
        </header>
       </>
     );
};

export default OuterHeader;
